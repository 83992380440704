.scheduling-hour-button {
  border: 1px solid var(--lighter);
  background: var(--primary-ultralight);
  border-radius: 6px;
  border: 1px solid var(--primary-superlight);
  height: 50px;
  width: 100%;
  transition: border 0.1s ease-in, background-color 0.1s ease-in;
}

.scheduling-hour-button:not(.selected) {
  transition: border 0.1s ease-in, background-color 0.1s ease-in,
    width 0.1s ease-in;
}

.scheduling-hour-button.selected {
  width: calc(100% - 100px - 0.5rem);
  background: var(--primary-superlighter);
  border: 1px solid var(--primary) !important;
  color: var(--dark);
  font-weight: bold;
}

.scheduling-hour-button:hover {
  background: var(--primary-superlighter) !important;
  border: 1px solid var(--primary-lighter) !important;
}

.scheduling-hour-button.disabled,
.scheduling-hour-button.disabled:hover {
  background: white !important;
  color: var(--lighter) !important;
  border: 1px solid white !important;
  cursor: not-allowed;
}
