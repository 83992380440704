@media (min-width: 1201px) {
  .pln-store-selector-container {
    box-shadow: 0px 5px 8px 0px rgba(33, 37, 41, 0.1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .pln-store-selector-container .left-col {
    border-bottom-left-radius: 6px;
  }

  .pln-store-selector-container .right-col {
    border-bottom-right-radius: 6px;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .pln-store-selector-container {
    border-bottom: 1px solid var(--lighter);
  }
}

.pln-store-list-item::part(native) {
  padding-left: 0px;
  padding-right: 0px;
  --inner-padding-start: 1.5rem;
  --inner-padding-end: 1.5rem;
  --inner-padding-top: 0.5rem;
  --inner-padding-bottom: 0.5rem;
  background: var(--white);
}

.pln-store-list-item.item-active::part(native) {
  background: var(--primary-superlighter);
}

.pln-store-list-item.item-active::part(native):hover {
  background: var(--primary-superlight);
}
