.react-calendar {
  width: 100%;
  max-width: 100%;
  background: #fff;
  line-height: 1.125em;
  font-family: "Karla", sans-serif;
  font-size: 1rem;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 6px;
  color: var(--dark);
  border: none;
}

.react-calendar__navigation__arrow {
  transition: all 0.1s ease-in;
  border-radius: 6px;
  font-size: 24px;
  border: 1px solid var(--lighter) !important;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 7px 0px;
}

.react-calendar__navigation button:enabled:hover {
  background: var(--superlight);
}

.react-calendar__navigation button:disabled {
  background: white;
  color: var(--lighter);
  border: 1px solid var(--midlighter) !important;
  box-shadow: none;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  border-radius: 6px;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  border: 1px solid var(--lighter) !important;
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 7px 0px;
}

.react-calendar__navigation__label:focus {
  background-color: white !important;
}

.react-calendar__navigation__label:hover {
  background-color: var(--superlight) !important;
}

.react-calendar__tile {
  font-size: 16px;
  padding: 0 !important;
}

.react-calendar__tile abbr {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0.25rem;
  border-radius: 6px;
  border: 1px solid transparent !important;
  display: block;
}

.react-calendar__viewContainer {
  border: 1px solid var(--midlighter);
  border-radius: 6px;
  padding: 0.5rem;
}

.react-calendar__navigation__label
  .react-calendar__navigation__label__labelText--from {
  font-size: 16px;
  font-weight: 500;
}

.react-calendar__tile--now {
  background: white !important;
}

.react-calendar__tile--now abbr {
  background: var(--primary-ultralight);
  transition: all 0.1s ease-in;
  border: 1px solid var(--primary-superlight) !important;
  text-decoration: underline;
}

.react-calendar__tile--now:hover abbr,
.react-calendar__tile--now:focus abbr,
.react-calendar__tile--active:hover abbr {
  background: var(--primary-superlighter) !important;
}

.react-calendar__tile {
  background: white !important;
  transition: all 0.1s ease-in;
}

.react-calendar__tile--active {
  background: white !important;
}

.react-calendar__tile:enabled abbr {
  background: var(--primary-ultralight);
  transition: all 0.1s ease-in;
  border: 1px solid var(--primary-superlight) !important;
}

.react-calendar__tile:enabled:hover abbr,
.react-calendar__tile:enabled:focus abbr {
  background: var(--primary-superlighter) !important;
  border: 1px solid var(--primary-lighter) !important;
}

.react-calendar__tile:enabled.react-calendar__tile--active abbr {
  background: var(--primary-superlighter);
  transition: all 0.1s ease-in;
  border: 1px solid var(--primary) !important;
  color: var(--dark);
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend:not(
    .react-calendar__month-view__days__day--neighboringMonth
  ) {
  color: var(--dark);
}

.react-calendar__navigation__label:disabled {
  color: var(--dark) !important;
  border: 1px solid transparent !important;
  background: white !important;
}

.react-calendar__tile:disabled {
  background: white;
  color: var(--lighter);
}
