@font-face {
  font-family: "Karla";
  font-weight: 400;
  src: url("../../assets/fonts/Karla/Karla-Regular.ttf");
}

@font-face {
  font-family: "Karla";
  font-weight: 500;
  src: url("../../assets/fonts/Karla/Karla-Medium.ttf");
}

@font-face {
  font-family: "Karla";
  font-weight: 700;
  src: url("../../assets/fonts/Karla/Karla-Bold.ttf");
}

@font-face {
  font-family: "Karla";
  font-weight: 800;
  src: url("../../assets/fonts/Karla/Karla-ExtraBold.ttf");
}

html,
body {
  font-family: "Karla", sans-serif;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m--9 {
  margin: -10rem !important;
}

.mt--9,
.my--9 {
  margin-top: -10rem !important;
}

.mr--9,
.mx--9 {
  margin-right: -10rem !important;
}

.mb--9,
.my--9 {
  margin-bottom: -10rem !important;
}

.ml--9,
.mx--9 {
  margin-left: -10rem !important;
}

.m--8 {
  margin: -8rem !important;
}

.mt--8,
.my--8 {
  margin-top: -8rem !important;
}

.mr--8,
.mx--8 {
  margin-right: -8rem !important;
}

.mb--8,
.my--8 {
  margin-bottom: -8rem !important;
}

.ml--8,
.mx--8 {
  margin-left: -8rem !important;
}

.m--7 {
  margin: -6rem !important;
}

.mt--7,
.my--7 {
  margin-top: -6rem !important;
}

.mr--7,
.mx--7 {
  margin-right: -6rem !important;
}

.mb--7,
.my--7 {
  margin-bottom: -6rem !important;
}

.ml--7,
.mx--7 {
  margin-left: -6rem !important;
}

.m--6 {
  margin: -4.5rem !important;
}

.mt--6,
.my--6 {
  margin-top: -4.5rem !important;
}

.mr--6,
.mx--6 {
  margin-right: -4.5rem !important;
}

.mb--6,
.my--6 {
  margin-bottom: -4.5rem !important;
}

.ml--6,
.mx--6 {
  margin-left: -4.5rem !important;
}

.m--5 {
  margin: -3rem !important;
}

.mt--5,
.my--5 {
  margin-top: -3rem !important;
}

.mr--5,
.mx--5 {
  margin-right: -3rem !important;
}

.mb--5,
.my--5 {
  margin-bottom: -3rem !important;
}

.ml--5,
.mx--5 {
  margin-left: -3rem !important;
}

.m--4 {
  margin: -1.5rem !important;
}

.mt--4,
.my--4 {
  margin-top: -1.5rem !important;
}

.mr--4,
.mx--4 {
  margin-right: -1.5rem !important;
}

.mb--4,
.my--4 {
  margin-bottom: -1.5rem !important;
}

.ml--4,
.mx--4 {
  margin-left: -1.5rem !important;
}

.m--3 {
  margin: -1rem !important;
}

.mt--3,
.my--3 {
  margin-top: -1rem !important;
}

.mr--3,
.mx--3 {
  margin-right: -1rem !important;
}

.mb--3,
.my--3 {
  margin-bottom: -1rem !important;
}

.ml--3,
.mx--3 {
  margin-left: -1rem !important;
}

.m--2 {
  margin: -0.5rem !important;
}

.mt--2,
.my--2 {
  margin-top: -0.5rem !important;
}

.mr--2,
.mx--2 {
  margin-right: -0.5rem !important;
}

.mb--2,
.my--2 {
  margin-bottom: -0.5rem !important;
}

.ml--2,
.mx--2 {
  margin-left: -0.5rem !important;
}

.m--1 {
  margin: -0.25rem !important;
}

.mt--1,
.my--1 {
  margin-top: -0.25rem !important;
}

.mr--1,
.mx--1 {
  margin-right: -0.25rem !important;
}

.mb--1,
.my--1 {
  margin-bottom: -0.25rem !important;
}

.ml--1,
.mx--1 {
  margin-left: -0.25rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.mt-8,
.my-8 {
  margin-top: 8rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 8rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 8rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 8rem !important;
}

.m-9 {
  margin: 10rem !important;
}

.mt-9,
.my-9 {
  margin-top: 10rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 10rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 10rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 10rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p--9 {
  padding: -10rem !important;
}

.pt--9,
.py--9 {
  padding-top: -10rem !important;
}

.pr--9,
.px--9 {
  padding-right: -10rem !important;
}

.pb--9,
.py--9 {
  padding-bottom: -10rem !important;
}

.pl--9,
.px--9 {
  padding-left: -10rem !important;
}

.p--8 {
  padding: -8rem !important;
}

.pt--8,
.py--8 {
  padding-top: -8rem !important;
}

.pr--8,
.px--8 {
  padding-right: -8rem !important;
}

.pb--8,
.py--8 {
  padding-bottom: -8rem !important;
}

.pl--8,
.px--8 {
  padding-left: -8rem !important;
}

.p--7 {
  padding: -6rem !important;
}

.pt--7,
.py--7 {
  padding-top: -6rem !important;
}

.pr--7,
.px--7 {
  padding-right: -6rem !important;
}

.pb--7,
.py--7 {
  padding-bottom: -6rem !important;
}

.pl--7,
.px--7 {
  padding-left: -6rem !important;
}

.p--6 {
  padding: -4.5rem !important;
}

.pt--6,
.py--6 {
  padding-top: -4.5rem !important;
}

.pr--6,
.px--6 {
  padding-right: -4.5rem !important;
}

.pb--6,
.py--6 {
  padding-bottom: -4.5rem !important;
}

.pl--6,
.px--6 {
  padding-left: -4.5rem !important;
}

.p--5 {
  padding: -3rem !important;
}

.pt--5,
.py--5 {
  padding-top: -3rem !important;
}

.pr--5,
.px--5 {
  padding-right: -3rem !important;
}

.pb--5,
.py--5 {
  padding-bottom: -3rem !important;
}

.pl--5,
.px--5 {
  padding-left: -3rem !important;
}

.p--4 {
  padding: -1.5rem !important;
}

.pt--4,
.py--4 {
  padding-top: -1.5rem !important;
}

.pr--4,
.px--4 {
  padding-right: -1.5rem !important;
}

.pb--4,
.py--4 {
  padding-bottom: -1.5rem !important;
}

.pl--4,
.px--4 {
  padding-left: -1.5rem !important;
}

.p--3 {
  padding: -1rem !important;
}

.pt--3,
.py--3 {
  padding-top: -1rem !important;
}

.pr--3,
.px--3 {
  padding-right: -1rem !important;
}

.pb--3,
.py--3 {
  padding-bottom: -1rem !important;
}

.pl--3,
.px--3 {
  padding-left: -1rem !important;
}

.p--2 {
  padding: -0.5rem !important;
}

.pt--2,
.py--2 {
  padding-top: -0.5rem !important;
}

.pr--2,
.px--2 {
  padding-right: -0.5rem !important;
}

.pb--2,
.py--2 {
  padding-bottom: -0.5rem !important;
}

.pl--2,
.px--2 {
  padding-left: -0.5rem !important;
}

.p--1 {
  padding: -0.25rem !important;
}

.pt--1,
.py--1 {
  padding-top: -0.25rem !important;
}

.pr--1,
.px--1 {
  padding-right: -0.25rem !important;
}

.pb--1,
.py--1 {
  padding-bottom: -0.25rem !important;
}

.pl--1,
.px--1 {
  padding-left: -0.25rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.pt-8,
.py-8 {
  padding-top: 8rem !important;
}

.pr-8,
.px-8 {
  padding-right: 8rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 8rem !important;
}

.pl-8,
.px-8 {
  padding-left: 8rem !important;
}

.p-9 {
  padding: 10rem !important;
}

.pt-9,
.py-9 {
  padding-top: 10rem !important;
}

.pr-9,
.px-9 {
  padding-right: 10rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 10rem !important;
}

.pl-9,
.px-9 {
  padding-left: 10rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm--9 {
    margin: -10rem !important;
  }
  .mt-sm--9,
  .my-sm--9 {
    margin-top: -10rem !important;
  }
  .mr-sm--9,
  .mx-sm--9 {
    margin-right: -10rem !important;
  }
  .mb-sm--9,
  .my-sm--9 {
    margin-bottom: -10rem !important;
  }
  .ml-sm--9,
  .mx-sm--9 {
    margin-left: -10rem !important;
  }
  .m-sm--8 {
    margin: -8rem !important;
  }
  .mt-sm--8,
  .my-sm--8 {
    margin-top: -8rem !important;
  }
  .mr-sm--8,
  .mx-sm--8 {
    margin-right: -8rem !important;
  }
  .mb-sm--8,
  .my-sm--8 {
    margin-bottom: -8rem !important;
  }
  .ml-sm--8,
  .mx-sm--8 {
    margin-left: -8rem !important;
  }
  .m-sm--7 {
    margin: -6rem !important;
  }
  .mt-sm--7,
  .my-sm--7 {
    margin-top: -6rem !important;
  }
  .mr-sm--7,
  .mx-sm--7 {
    margin-right: -6rem !important;
  }
  .mb-sm--7,
  .my-sm--7 {
    margin-bottom: -6rem !important;
  }
  .ml-sm--7,
  .mx-sm--7 {
    margin-left: -6rem !important;
  }
  .m-sm--6 {
    margin: -4.5rem !important;
  }
  .mt-sm--6,
  .my-sm--6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm--6,
  .mx-sm--6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm--6,
  .my-sm--6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm--6,
  .mx-sm--6 {
    margin-left: -4.5rem !important;
  }
  .m-sm--5 {
    margin: -3rem !important;
  }
  .mt-sm--5,
  .my-sm--5 {
    margin-top: -3rem !important;
  }
  .mr-sm--5,
  .mx-sm--5 {
    margin-right: -3rem !important;
  }
  .mb-sm--5,
  .my-sm--5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm--5,
  .mx-sm--5 {
    margin-left: -3rem !important;
  }
  .m-sm--4 {
    margin: -1.5rem !important;
  }
  .mt-sm--4,
  .my-sm--4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm--4,
  .mx-sm--4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm--4,
  .my-sm--4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm--4,
  .mx-sm--4 {
    margin-left: -1.5rem !important;
  }
  .m-sm--3 {
    margin: -1rem !important;
  }
  .mt-sm--3,
  .my-sm--3 {
    margin-top: -1rem !important;
  }
  .mr-sm--3,
  .mx-sm--3 {
    margin-right: -1rem !important;
  }
  .mb-sm--3,
  .my-sm--3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm--3,
  .mx-sm--3 {
    margin-left: -1rem !important;
  }
  .m-sm--2 {
    margin: -0.5rem !important;
  }
  .mt-sm--2,
  .my-sm--2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm--2,
  .mx-sm--2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm--2,
  .my-sm--2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm--2,
  .mx-sm--2 {
    margin-left: -0.5rem !important;
  }
  .m-sm--1 {
    margin: -0.25rem !important;
  }
  .mt-sm--1,
  .my-sm--1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm--1,
  .mx-sm--1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm--1,
  .my-sm--1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm--1,
  .mx-sm--1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important;
  }
  .m-sm-9 {
    margin: 10rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 10rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 10rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 10rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm--9 {
    padding: -10rem !important;
  }
  .pt-sm--9,
  .py-sm--9 {
    padding-top: -10rem !important;
  }
  .pr-sm--9,
  .px-sm--9 {
    padding-right: -10rem !important;
  }
  .pb-sm--9,
  .py-sm--9 {
    padding-bottom: -10rem !important;
  }
  .pl-sm--9,
  .px-sm--9 {
    padding-left: -10rem !important;
  }
  .p-sm--8 {
    padding: -8rem !important;
  }
  .pt-sm--8,
  .py-sm--8 {
    padding-top: -8rem !important;
  }
  .pr-sm--8,
  .px-sm--8 {
    padding-right: -8rem !important;
  }
  .pb-sm--8,
  .py-sm--8 {
    padding-bottom: -8rem !important;
  }
  .pl-sm--8,
  .px-sm--8 {
    padding-left: -8rem !important;
  }
  .p-sm--7 {
    padding: -6rem !important;
  }
  .pt-sm--7,
  .py-sm--7 {
    padding-top: -6rem !important;
  }
  .pr-sm--7,
  .px-sm--7 {
    padding-right: -6rem !important;
  }
  .pb-sm--7,
  .py-sm--7 {
    padding-bottom: -6rem !important;
  }
  .pl-sm--7,
  .px-sm--7 {
    padding-left: -6rem !important;
  }
  .p-sm--6 {
    padding: -4.5rem !important;
  }
  .pt-sm--6,
  .py-sm--6 {
    padding-top: -4.5rem !important;
  }
  .pr-sm--6,
  .px-sm--6 {
    padding-right: -4.5rem !important;
  }
  .pb-sm--6,
  .py-sm--6 {
    padding-bottom: -4.5rem !important;
  }
  .pl-sm--6,
  .px-sm--6 {
    padding-left: -4.5rem !important;
  }
  .p-sm--5 {
    padding: -3rem !important;
  }
  .pt-sm--5,
  .py-sm--5 {
    padding-top: -3rem !important;
  }
  .pr-sm--5,
  .px-sm--5 {
    padding-right: -3rem !important;
  }
  .pb-sm--5,
  .py-sm--5 {
    padding-bottom: -3rem !important;
  }
  .pl-sm--5,
  .px-sm--5 {
    padding-left: -3rem !important;
  }
  .p-sm--4 {
    padding: -1.5rem !important;
  }
  .pt-sm--4,
  .py-sm--4 {
    padding-top: -1.5rem !important;
  }
  .pr-sm--4,
  .px-sm--4 {
    padding-right: -1.5rem !important;
  }
  .pb-sm--4,
  .py-sm--4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-sm--4,
  .px-sm--4 {
    padding-left: -1.5rem !important;
  }
  .p-sm--3 {
    padding: -1rem !important;
  }
  .pt-sm--3,
  .py-sm--3 {
    padding-top: -1rem !important;
  }
  .pr-sm--3,
  .px-sm--3 {
    padding-right: -1rem !important;
  }
  .pb-sm--3,
  .py-sm--3 {
    padding-bottom: -1rem !important;
  }
  .pl-sm--3,
  .px-sm--3 {
    padding-left: -1rem !important;
  }
  .p-sm--2 {
    padding: -0.5rem !important;
  }
  .pt-sm--2,
  .py-sm--2 {
    padding-top: -0.5rem !important;
  }
  .pr-sm--2,
  .px-sm--2 {
    padding-right: -0.5rem !important;
  }
  .pb-sm--2,
  .py-sm--2 {
    padding-bottom: -0.5rem !important;
  }
  .pl-sm--2,
  .px-sm--2 {
    padding-left: -0.5rem !important;
  }
  .p-sm--1 {
    padding: -0.25rem !important;
  }
  .pt-sm--1,
  .py-sm--1 {
    padding-top: -0.25rem !important;
  }
  .pr-sm--1,
  .px-sm--1 {
    padding-right: -0.25rem !important;
  }
  .pb-sm--1,
  .py-sm--1 {
    padding-bottom: -0.25rem !important;
  }
  .pl-sm--1,
  .px-sm--1 {
    padding-left: -0.25rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important;
  }
  .p-sm-9 {
    padding: 10rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 10rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 10rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 10rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md--9 {
    margin: -10rem !important;
  }
  .mt-md--9,
  .my-md--9 {
    margin-top: -10rem !important;
  }
  .mr-md--9,
  .mx-md--9 {
    margin-right: -10rem !important;
  }
  .mb-md--9,
  .my-md--9 {
    margin-bottom: -10rem !important;
  }
  .ml-md--9,
  .mx-md--9 {
    margin-left: -10rem !important;
  }
  .m-md--8 {
    margin: -8rem !important;
  }
  .mt-md--8,
  .my-md--8 {
    margin-top: -8rem !important;
  }
  .mr-md--8,
  .mx-md--8 {
    margin-right: -8rem !important;
  }
  .mb-md--8,
  .my-md--8 {
    margin-bottom: -8rem !important;
  }
  .ml-md--8,
  .mx-md--8 {
    margin-left: -8rem !important;
  }
  .m-md--7 {
    margin: -6rem !important;
  }
  .mt-md--7,
  .my-md--7 {
    margin-top: -6rem !important;
  }
  .mr-md--7,
  .mx-md--7 {
    margin-right: -6rem !important;
  }
  .mb-md--7,
  .my-md--7 {
    margin-bottom: -6rem !important;
  }
  .ml-md--7,
  .mx-md--7 {
    margin-left: -6rem !important;
  }
  .m-md--6 {
    margin: -4.5rem !important;
  }
  .mt-md--6,
  .my-md--6 {
    margin-top: -4.5rem !important;
  }
  .mr-md--6,
  .mx-md--6 {
    margin-right: -4.5rem !important;
  }
  .mb-md--6,
  .my-md--6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md--6,
  .mx-md--6 {
    margin-left: -4.5rem !important;
  }
  .m-md--5 {
    margin: -3rem !important;
  }
  .mt-md--5,
  .my-md--5 {
    margin-top: -3rem !important;
  }
  .mr-md--5,
  .mx-md--5 {
    margin-right: -3rem !important;
  }
  .mb-md--5,
  .my-md--5 {
    margin-bottom: -3rem !important;
  }
  .ml-md--5,
  .mx-md--5 {
    margin-left: -3rem !important;
  }
  .m-md--4 {
    margin: -1.5rem !important;
  }
  .mt-md--4,
  .my-md--4 {
    margin-top: -1.5rem !important;
  }
  .mr-md--4,
  .mx-md--4 {
    margin-right: -1.5rem !important;
  }
  .mb-md--4,
  .my-md--4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md--4,
  .mx-md--4 {
    margin-left: -1.5rem !important;
  }
  .m-md--3 {
    margin: -1rem !important;
  }
  .mt-md--3,
  .my-md--3 {
    margin-top: -1rem !important;
  }
  .mr-md--3,
  .mx-md--3 {
    margin-right: -1rem !important;
  }
  .mb-md--3,
  .my-md--3 {
    margin-bottom: -1rem !important;
  }
  .ml-md--3,
  .mx-md--3 {
    margin-left: -1rem !important;
  }
  .m-md--2 {
    margin: -0.5rem !important;
  }
  .mt-md--2,
  .my-md--2 {
    margin-top: -0.5rem !important;
  }
  .mr-md--2,
  .mx-md--2 {
    margin-right: -0.5rem !important;
  }
  .mb-md--2,
  .my-md--2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md--2,
  .mx-md--2 {
    margin-left: -0.5rem !important;
  }
  .m-md--1 {
    margin: -0.25rem !important;
  }
  .mt-md--1,
  .my-md--1 {
    margin-top: -0.25rem !important;
  }
  .mr-md--1,
  .mx-md--1 {
    margin-right: -0.25rem !important;
  }
  .mb-md--1,
  .my-md--1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md--1,
  .mx-md--1 {
    margin-left: -0.25rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important;
  }
  .m-md-9 {
    margin: 10rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 10rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 10rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 10rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 10rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md--9 {
    padding: -10rem !important;
  }
  .pt-md--9,
  .py-md--9 {
    padding-top: -10rem !important;
  }
  .pr-md--9,
  .px-md--9 {
    padding-right: -10rem !important;
  }
  .pb-md--9,
  .py-md--9 {
    padding-bottom: -10rem !important;
  }
  .pl-md--9,
  .px-md--9 {
    padding-left: -10rem !important;
  }
  .p-md--8 {
    padding: -8rem !important;
  }
  .pt-md--8,
  .py-md--8 {
    padding-top: -8rem !important;
  }
  .pr-md--8,
  .px-md--8 {
    padding-right: -8rem !important;
  }
  .pb-md--8,
  .py-md--8 {
    padding-bottom: -8rem !important;
  }
  .pl-md--8,
  .px-md--8 {
    padding-left: -8rem !important;
  }
  .p-md--7 {
    padding: -6rem !important;
  }
  .pt-md--7,
  .py-md--7 {
    padding-top: -6rem !important;
  }
  .pr-md--7,
  .px-md--7 {
    padding-right: -6rem !important;
  }
  .pb-md--7,
  .py-md--7 {
    padding-bottom: -6rem !important;
  }
  .pl-md--7,
  .px-md--7 {
    padding-left: -6rem !important;
  }
  .p-md--6 {
    padding: -4.5rem !important;
  }
  .pt-md--6,
  .py-md--6 {
    padding-top: -4.5rem !important;
  }
  .pr-md--6,
  .px-md--6 {
    padding-right: -4.5rem !important;
  }
  .pb-md--6,
  .py-md--6 {
    padding-bottom: -4.5rem !important;
  }
  .pl-md--6,
  .px-md--6 {
    padding-left: -4.5rem !important;
  }
  .p-md--5 {
    padding: -3rem !important;
  }
  .pt-md--5,
  .py-md--5 {
    padding-top: -3rem !important;
  }
  .pr-md--5,
  .px-md--5 {
    padding-right: -3rem !important;
  }
  .pb-md--5,
  .py-md--5 {
    padding-bottom: -3rem !important;
  }
  .pl-md--5,
  .px-md--5 {
    padding-left: -3rem !important;
  }
  .p-md--4 {
    padding: -1.5rem !important;
  }
  .pt-md--4,
  .py-md--4 {
    padding-top: -1.5rem !important;
  }
  .pr-md--4,
  .px-md--4 {
    padding-right: -1.5rem !important;
  }
  .pb-md--4,
  .py-md--4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-md--4,
  .px-md--4 {
    padding-left: -1.5rem !important;
  }
  .p-md--3 {
    padding: -1rem !important;
  }
  .pt-md--3,
  .py-md--3 {
    padding-top: -1rem !important;
  }
  .pr-md--3,
  .px-md--3 {
    padding-right: -1rem !important;
  }
  .pb-md--3,
  .py-md--3 {
    padding-bottom: -1rem !important;
  }
  .pl-md--3,
  .px-md--3 {
    padding-left: -1rem !important;
  }
  .p-md--2 {
    padding: -0.5rem !important;
  }
  .pt-md--2,
  .py-md--2 {
    padding-top: -0.5rem !important;
  }
  .pr-md--2,
  .px-md--2 {
    padding-right: -0.5rem !important;
  }
  .pb-md--2,
  .py-md--2 {
    padding-bottom: -0.5rem !important;
  }
  .pl-md--2,
  .px-md--2 {
    padding-left: -0.5rem !important;
  }
  .p-md--1 {
    padding: -0.25rem !important;
  }
  .pt-md--1,
  .py-md--1 {
    padding-top: -0.25rem !important;
  }
  .pr-md--1,
  .px-md--1 {
    padding-right: -0.25rem !important;
  }
  .pb-md--1,
  .py-md--1 {
    padding-bottom: -0.25rem !important;
  }
  .pl-md--1,
  .px-md--1 {
    padding-left: -0.25rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important;
  }
  .p-md-9 {
    padding: 10rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 10rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 10rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 10rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 10rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg--9 {
    margin: -10rem !important;
  }
  .mt-lg--9,
  .my-lg--9 {
    margin-top: -10rem !important;
  }
  .mr-lg--9,
  .mx-lg--9 {
    margin-right: -10rem !important;
  }
  .mb-lg--9,
  .my-lg--9 {
    margin-bottom: -10rem !important;
  }
  .ml-lg--9,
  .mx-lg--9 {
    margin-left: -10rem !important;
  }
  .m-lg--8 {
    margin: -8rem !important;
  }
  .mt-lg--8,
  .my-lg--8 {
    margin-top: -8rem !important;
  }
  .mr-lg--8,
  .mx-lg--8 {
    margin-right: -8rem !important;
  }
  .mb-lg--8,
  .my-lg--8 {
    margin-bottom: -8rem !important;
  }
  .ml-lg--8,
  .mx-lg--8 {
    margin-left: -8rem !important;
  }
  .m-lg--7 {
    margin: -6rem !important;
  }
  .mt-lg--7,
  .my-lg--7 {
    margin-top: -6rem !important;
  }
  .mr-lg--7,
  .mx-lg--7 {
    margin-right: -6rem !important;
  }
  .mb-lg--7,
  .my-lg--7 {
    margin-bottom: -6rem !important;
  }
  .ml-lg--7,
  .mx-lg--7 {
    margin-left: -6rem !important;
  }
  .m-lg--6 {
    margin: -4.5rem !important;
  }
  .mt-lg--6,
  .my-lg--6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg--6,
  .mx-lg--6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg--6,
  .my-lg--6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg--6,
  .mx-lg--6 {
    margin-left: -4.5rem !important;
  }
  .m-lg--5 {
    margin: -3rem !important;
  }
  .mt-lg--5,
  .my-lg--5 {
    margin-top: -3rem !important;
  }
  .mr-lg--5,
  .mx-lg--5 {
    margin-right: -3rem !important;
  }
  .mb-lg--5,
  .my-lg--5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg--5,
  .mx-lg--5 {
    margin-left: -3rem !important;
  }
  .m-lg--4 {
    margin: -1.5rem !important;
  }
  .mt-lg--4,
  .my-lg--4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg--4,
  .mx-lg--4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg--4,
  .my-lg--4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg--4,
  .mx-lg--4 {
    margin-left: -1.5rem !important;
  }
  .m-lg--3 {
    margin: -1rem !important;
  }
  .mt-lg--3,
  .my-lg--3 {
    margin-top: -1rem !important;
  }
  .mr-lg--3,
  .mx-lg--3 {
    margin-right: -1rem !important;
  }
  .mb-lg--3,
  .my-lg--3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg--3,
  .mx-lg--3 {
    margin-left: -1rem !important;
  }
  .m-lg--2 {
    margin: -0.5rem !important;
  }
  .mt-lg--2,
  .my-lg--2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg--2,
  .mx-lg--2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg--2,
  .my-lg--2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg--2,
  .mx-lg--2 {
    margin-left: -0.5rem !important;
  }
  .m-lg--1 {
    margin: -0.25rem !important;
  }
  .mt-lg--1,
  .my-lg--1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg--1,
  .mx-lg--1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg--1,
  .my-lg--1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg--1,
  .mx-lg--1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important;
  }
  .m-lg-9 {
    margin: 10rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 10rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 10rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 10rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg--9 {
    padding: -10rem !important;
  }
  .pt-lg--9,
  .py-lg--9 {
    padding-top: -10rem !important;
  }
  .pr-lg--9,
  .px-lg--9 {
    padding-right: -10rem !important;
  }
  .pb-lg--9,
  .py-lg--9 {
    padding-bottom: -10rem !important;
  }
  .pl-lg--9,
  .px-lg--9 {
    padding-left: -10rem !important;
  }
  .p-lg--8 {
    padding: -8rem !important;
  }
  .pt-lg--8,
  .py-lg--8 {
    padding-top: -8rem !important;
  }
  .pr-lg--8,
  .px-lg--8 {
    padding-right: -8rem !important;
  }
  .pb-lg--8,
  .py-lg--8 {
    padding-bottom: -8rem !important;
  }
  .pl-lg--8,
  .px-lg--8 {
    padding-left: -8rem !important;
  }
  .p-lg--7 {
    padding: -6rem !important;
  }
  .pt-lg--7,
  .py-lg--7 {
    padding-top: -6rem !important;
  }
  .pr-lg--7,
  .px-lg--7 {
    padding-right: -6rem !important;
  }
  .pb-lg--7,
  .py-lg--7 {
    padding-bottom: -6rem !important;
  }
  .pl-lg--7,
  .px-lg--7 {
    padding-left: -6rem !important;
  }
  .p-lg--6 {
    padding: -4.5rem !important;
  }
  .pt-lg--6,
  .py-lg--6 {
    padding-top: -4.5rem !important;
  }
  .pr-lg--6,
  .px-lg--6 {
    padding-right: -4.5rem !important;
  }
  .pb-lg--6,
  .py-lg--6 {
    padding-bottom: -4.5rem !important;
  }
  .pl-lg--6,
  .px-lg--6 {
    padding-left: -4.5rem !important;
  }
  .p-lg--5 {
    padding: -3rem !important;
  }
  .pt-lg--5,
  .py-lg--5 {
    padding-top: -3rem !important;
  }
  .pr-lg--5,
  .px-lg--5 {
    padding-right: -3rem !important;
  }
  .pb-lg--5,
  .py-lg--5 {
    padding-bottom: -3rem !important;
  }
  .pl-lg--5,
  .px-lg--5 {
    padding-left: -3rem !important;
  }
  .p-lg--4 {
    padding: -1.5rem !important;
  }
  .pt-lg--4,
  .py-lg--4 {
    padding-top: -1.5rem !important;
  }
  .pr-lg--4,
  .px-lg--4 {
    padding-right: -1.5rem !important;
  }
  .pb-lg--4,
  .py-lg--4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-lg--4,
  .px-lg--4 {
    padding-left: -1.5rem !important;
  }
  .p-lg--3 {
    padding: -1rem !important;
  }
  .pt-lg--3,
  .py-lg--3 {
    padding-top: -1rem !important;
  }
  .pr-lg--3,
  .px-lg--3 {
    padding-right: -1rem !important;
  }
  .pb-lg--3,
  .py-lg--3 {
    padding-bottom: -1rem !important;
  }
  .pl-lg--3,
  .px-lg--3 {
    padding-left: -1rem !important;
  }
  .p-lg--2 {
    padding: -0.5rem !important;
  }
  .pt-lg--2,
  .py-lg--2 {
    padding-top: -0.5rem !important;
  }
  .pr-lg--2,
  .px-lg--2 {
    padding-right: -0.5rem !important;
  }
  .pb-lg--2,
  .py-lg--2 {
    padding-bottom: -0.5rem !important;
  }
  .pl-lg--2,
  .px-lg--2 {
    padding-left: -0.5rem !important;
  }
  .p-lg--1 {
    padding: -0.25rem !important;
  }
  .pt-lg--1,
  .py-lg--1 {
    padding-top: -0.25rem !important;
  }
  .pr-lg--1,
  .px-lg--1 {
    padding-right: -0.25rem !important;
  }
  .pb-lg--1,
  .py-lg--1 {
    padding-bottom: -0.25rem !important;
  }
  .pl-lg--1,
  .px-lg--1 {
    padding-left: -0.25rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important;
  }
  .p-lg-9 {
    padding: 10rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 10rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 10rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 10rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl--9 {
    margin: -10rem !important;
  }
  .mt-xl--9,
  .my-xl--9 {
    margin-top: -10rem !important;
  }
  .mr-xl--9,
  .mx-xl--9 {
    margin-right: -10rem !important;
  }
  .mb-xl--9,
  .my-xl--9 {
    margin-bottom: -10rem !important;
  }
  .ml-xl--9,
  .mx-xl--9 {
    margin-left: -10rem !important;
  }
  .m-xl--8 {
    margin: -8rem !important;
  }
  .mt-xl--8,
  .my-xl--8 {
    margin-top: -8rem !important;
  }
  .mr-xl--8,
  .mx-xl--8 {
    margin-right: -8rem !important;
  }
  .mb-xl--8,
  .my-xl--8 {
    margin-bottom: -8rem !important;
  }
  .ml-xl--8,
  .mx-xl--8 {
    margin-left: -8rem !important;
  }
  .m-xl--7 {
    margin: -6rem !important;
  }
  .mt-xl--7,
  .my-xl--7 {
    margin-top: -6rem !important;
  }
  .mr-xl--7,
  .mx-xl--7 {
    margin-right: -6rem !important;
  }
  .mb-xl--7,
  .my-xl--7 {
    margin-bottom: -6rem !important;
  }
  .ml-xl--7,
  .mx-xl--7 {
    margin-left: -6rem !important;
  }
  .m-xl--6 {
    margin: -4.5rem !important;
  }
  .mt-xl--6,
  .my-xl--6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl--6,
  .mx-xl--6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl--6,
  .my-xl--6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl--6,
  .mx-xl--6 {
    margin-left: -4.5rem !important;
  }
  .m-xl--5 {
    margin: -3rem !important;
  }
  .mt-xl--5,
  .my-xl--5 {
    margin-top: -3rem !important;
  }
  .mr-xl--5,
  .mx-xl--5 {
    margin-right: -3rem !important;
  }
  .mb-xl--5,
  .my-xl--5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl--5,
  .mx-xl--5 {
    margin-left: -3rem !important;
  }
  .m-xl--4 {
    margin: -1.5rem !important;
  }
  .mt-xl--4,
  .my-xl--4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl--4,
  .mx-xl--4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl--4,
  .my-xl--4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl--4,
  .mx-xl--4 {
    margin-left: -1.5rem !important;
  }
  .m-xl--3 {
    margin: -1rem !important;
  }
  .mt-xl--3,
  .my-xl--3 {
    margin-top: -1rem !important;
  }
  .mr-xl--3,
  .mx-xl--3 {
    margin-right: -1rem !important;
  }
  .mb-xl--3,
  .my-xl--3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl--3,
  .mx-xl--3 {
    margin-left: -1rem !important;
  }
  .m-xl--2 {
    margin: -0.5rem !important;
  }
  .mt-xl--2,
  .my-xl--2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl--2,
  .mx-xl--2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl--2,
  .my-xl--2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl--2,
  .mx-xl--2 {
    margin-left: -0.5rem !important;
  }
  .m-xl--1 {
    margin: -0.25rem !important;
  }
  .mt-xl--1,
  .my-xl--1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl--1,
  .mx-xl--1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl--1,
  .my-xl--1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl--1,
  .mx-xl--1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important;
  }
  .m-xl-9 {
    margin: 10rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 10rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 10rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 10rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl--9 {
    padding: -10rem !important;
  }
  .pt-xl--9,
  .py-xl--9 {
    padding-top: -10rem !important;
  }
  .pr-xl--9,
  .px-xl--9 {
    padding-right: -10rem !important;
  }
  .pb-xl--9,
  .py-xl--9 {
    padding-bottom: -10rem !important;
  }
  .pl-xl--9,
  .px-xl--9 {
    padding-left: -10rem !important;
  }
  .p-xl--8 {
    padding: -8rem !important;
  }
  .pt-xl--8,
  .py-xl--8 {
    padding-top: -8rem !important;
  }
  .pr-xl--8,
  .px-xl--8 {
    padding-right: -8rem !important;
  }
  .pb-xl--8,
  .py-xl--8 {
    padding-bottom: -8rem !important;
  }
  .pl-xl--8,
  .px-xl--8 {
    padding-left: -8rem !important;
  }
  .p-xl--7 {
    padding: -6rem !important;
  }
  .pt-xl--7,
  .py-xl--7 {
    padding-top: -6rem !important;
  }
  .pr-xl--7,
  .px-xl--7 {
    padding-right: -6rem !important;
  }
  .pb-xl--7,
  .py-xl--7 {
    padding-bottom: -6rem !important;
  }
  .pl-xl--7,
  .px-xl--7 {
    padding-left: -6rem !important;
  }
  .p-xl--6 {
    padding: -4.5rem !important;
  }
  .pt-xl--6,
  .py-xl--6 {
    padding-top: -4.5rem !important;
  }
  .pr-xl--6,
  .px-xl--6 {
    padding-right: -4.5rem !important;
  }
  .pb-xl--6,
  .py-xl--6 {
    padding-bottom: -4.5rem !important;
  }
  .pl-xl--6,
  .px-xl--6 {
    padding-left: -4.5rem !important;
  }
  .p-xl--5 {
    padding: -3rem !important;
  }
  .pt-xl--5,
  .py-xl--5 {
    padding-top: -3rem !important;
  }
  .pr-xl--5,
  .px-xl--5 {
    padding-right: -3rem !important;
  }
  .pb-xl--5,
  .py-xl--5 {
    padding-bottom: -3rem !important;
  }
  .pl-xl--5,
  .px-xl--5 {
    padding-left: -3rem !important;
  }
  .p-xl--4 {
    padding: -1.5rem !important;
  }
  .pt-xl--4,
  .py-xl--4 {
    padding-top: -1.5rem !important;
  }
  .pr-xl--4,
  .px-xl--4 {
    padding-right: -1.5rem !important;
  }
  .pb-xl--4,
  .py-xl--4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-xl--4,
  .px-xl--4 {
    padding-left: -1.5rem !important;
  }
  .p-xl--3 {
    padding: -1rem !important;
  }
  .pt-xl--3,
  .py-xl--3 {
    padding-top: -1rem !important;
  }
  .pr-xl--3,
  .px-xl--3 {
    padding-right: -1rem !important;
  }
  .pb-xl--3,
  .py-xl--3 {
    padding-bottom: -1rem !important;
  }
  .pl-xl--3,
  .px-xl--3 {
    padding-left: -1rem !important;
  }
  .p-xl--2 {
    padding: -0.5rem !important;
  }
  .pt-xl--2,
  .py-xl--2 {
    padding-top: -0.5rem !important;
  }
  .pr-xl--2,
  .px-xl--2 {
    padding-right: -0.5rem !important;
  }
  .pb-xl--2,
  .py-xl--2 {
    padding-bottom: -0.5rem !important;
  }
  .pl-xl--2,
  .px-xl--2 {
    padding-left: -0.5rem !important;
  }
  .p-xl--1 {
    padding: -0.25rem !important;
  }
  .pt-xl--1,
  .py-xl--1 {
    padding-top: -0.25rem !important;
  }
  .pr-xl--1,
  .px-xl--1 {
    padding-right: -0.25rem !important;
  }
  .pb-xl--1,
  .py-xl--1 {
    padding-bottom: -0.25rem !important;
  }
  .pl-xl--1,
  .px-xl--1 {
    padding-left: -0.25rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important;
  }
  .p-xl-9 {
    padding: 10rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 10rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 10rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 10rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #fb6340 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #dd5738 !important;
}

.text-secondary {
  color: #f7fafc !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #d2e3ee !important;
}

.text-success {
  color: #2dce89 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #24a46d !important;
}

.text-info {
  color: #11cdef !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0da5c0 !important;
}

.text-warning {
  color: #fb6340 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fa3a0e !important;
}

.text-danger {
  color: #f5365c !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ec0c38 !important;
}

.text-light {
  color: var(--light) !important;
}

.text-primary-darker {
  color: var(--primary-darker) !important;
}

.text-lighter {
  color: var(--lighter) !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #919ca6 !important;
}

.text-medium {
  color: var(--medium) !important;
}

.text-dark {
  color: #212529 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #0a0c0d !important;
}

.text-default {
  color: #172b4d !important;
}

a.text-default:hover,
a.text-default:focus {
  color: #0b1526 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-neutral {
  color: #fff !important;
}

a.text-neutral:hover,
a.text-neutral:focus {
  color: #e6e6e6 !important;
}

.text-darker {
  color: black !important;
}

a.text-darker:hover,
a.text-darker:focus {
  color: black !important;
}

.text-body {
  color: #525f7f !important;
}

.text-muted {
  color: #8898aa !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: no-drop;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.display-1,
.display-2,
.display-3 {
  color: var(--dark);
  font-weight: 700 !important;
  line-height: 1.2 !important;
}

.lead,
.lead-sm {
  color: var(--medium) !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 3rem !important;
  }

  .display-2 {
    font-size: 2.7rem !important;
  }

  .display-3 {
    font-size: 2.4rem !important;
  }

  .lead {
    font-size: 1.4rem !important;
  }

  .lead-sm {
    font-size: 1.1rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .display-1 {
    font-size: 2.7rem !important;
  }

  .display-2 {
    font-size: 2.4rem !important;
  }

  .display-3 {
    font-size: 2.1rem !important;
  }

  .lead {
    font-size: 1.3rem !important;
  }

  .lead-sm {
    font-size: 1rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .display-1 {
    font-size: 2.5rem !important;
  }

  .display-2 {
    font-size: 2.1rem !important;
  }

  .display-3 {
    font-size: 1.8rem !important;
  }

  .lead {
    font-size: 1.3rem !important;
  }

  .lead-sm {
    font-size: 0.95rem !important;
  }
}

@media (max-width: 767px) {
  .display-1 {
    font-size: 2.2rem !important;
  }

  .display-2 {
    font-size: 1.9rem !important;
  }

  .display-3 {
    font-size: 1.6rem !important;
  }

  .lead {
    font-size: 1.1rem !important;
  }

  .lead-sm {
    font-size: 0.95rem !important;
  }
}

.md .pln-input-web::part(native) {
  border: 1px solid var(--lighter) !important;
  transition: all 0.1s ease-in;
}

.md .pln-input-web ion-label {
  line-height: 1.1;
}

.md .pln-input-web.item-has-focus ion-label,
.md .pln-input-web.item-has-value ion-label {
  line-height: 1.4;
}

.pln-button-web.size-lg {
  height: 50px;
}

.pln-button-web.size-lg::part(native) {
  font-size: 20px;
}

.pln-button-web-icononly::part(native) {
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
}

.pln-button-web-listheader::part(native) {
  padding-inline-end: 0.25rem;
}

.pln-button-web {
  transition: all 0.1s ease-in !important;
  --background-hover: var(--ion-color-secondary);
  --box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.25);
  --border-radius: 6px;
  --color: white;
}

.ion-button-text-white {
  --color: white;
}

.pln-button-web-outline {
  transition: all 0.1s ease-in !important;
  font-weight: 700 !important;
  --border-radius: 6px;
}

.pln-button-web-link::part(native) {
  padding-left: 0;
  padding-right: 0;
  transition: all 0.1s ease-in !important;

  --border-radius: 6px;
}

.pln-button-web.ion-color-white::part(native) {
  color: var(--dark);
  border: 1px solid var(--lighter);
  box-shadow: none;
  background-color: white !important;
}

.pln-button-web.mfp-button::part(native) {
  color: var(--white);
  border: 1px solid #06e;
  box-shadow: none;
  background-color: #06e !important;
}

.pln-button-web.mfp-button:hover::part(native)::after,
.pln-button-web.mfp-button:focus::part(native)::after,
.pln-button-web.mfp-button:active::part(native)::after {
  color: var(--white);
  border: 1px solid #0055c7;
  box-shadow: none;
  background-color: #0055c7 !important;
}

.pln-button-web::part(native) {
  color: white;
  font-weight: 700;
  transition: all 0.1s ease-in;
}

.pln-button-web.button-icon-only.button-large::part(native) {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.md .pln-input-web::part(native):hover {
  border: 1px solid var(--light) !important;
}

.pln-button-web.disabled-light.button-disabled::part(native) {
  background: var(--midlighter) !important;
  border: 1px solid var(--light) !important;
  color: var(--dark);
  box-shadow: none !important;
}

@media (max-width: 991px) {
  .md .pln-input-web.item-fill-outline:not(.textarea)::part(native) {
    height: 40px;
  }
}

@media (min-width: 992px) {
  .md .pln-input-web.item-fill-outline:not(.textarea)::part(native) {
    height: 40px;
  }
}

.md .pln-input-web.small.item-fill-outline:not(.textarea)::part(native) {
  height: 30px;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0rem;
  min-height: 45px;
  font-size: 15px;
}

.md .pln-input-web.small ion-label {
  line-height: 1;
}

.md .pln-input-web.item-has-focus.small ion-label,
.md .pln-input-web.item-has-value.small ion-label {
  line-height: 1.2;
}

.pln-input-web.small.item-label-floating .sc-ion-input-md-h {
  --padding-top: 2px;
}

.pln-input-web.small.item-has-focus.item-label-floating .sc-ion-input-md-h,
.pln-input-web.small.item-has-value.item-label-floating .sc-ion-input-md-h {
  --padding-top: 8px;
}

.pln-input-web.small ion-textarea {
  margin-top: 0px;
}

.md .pln-input-web.x-small.item-fill-outline::part(native) {
  height: 35px;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0rem;
  min-height: 35px;
  font-size: 15px;
}

.md .pln-input-web.x-small ion-label {
  line-height: 1;
}

.md .pln-input-web.item-has-focus.x-small ion-label,
.md .pln-input-web.item-has-value.x-small ion-label {
  line-height: 1.2;
}

.pln-input-web.x-small.item-label-floating .sc-ion-input-md-h {
  --padding-top: 2px;
}

.pln-input-web.x-small.item-has-focus.item-label-floating .sc-ion-input-md-h,
.pln-input-web.x-small.item-has-value.item-label-floating .sc-ion-input-md-h {
  --padding-top: 8px;
}

@media (max-width: 767px) {
  .pln-input-web .sc-ion-input-md-h input.sc-ion-input-md,
  .pln-input-web .sc-ion-input-md-h a.sc-ion-input-md,
  .pln-input-web .sc-ion-input-md-h button.sc-ion-input-md {
    margin-top: 3px;
  }
}

.navbar-support-popup {
  --width: 200px;
}

.pln-card-web {
  border: 1px solid var(--lighter);
  box-shadow: none;
  background: white;
  border-radius: 6px;
}

.ion-content-bgwhite {
  --background: var(--white);
}

.signin-with-google-btn {
  border-radius: 6px;
  background: var(--white);
  height: 50px;
  padding: 0 12px;
  text-align: center;
  align-items: center;

  white-space: nowrap;
  display: flex;
  border: 1px solid var(--lighter);
  justify-content: center;
  width: 100%;

  transition: all 0.1s ease-in;
}

.signin-with-google-btn:hover {
  border: 1px solid var(--light);
}

@media (max-width: 767px) {
  .signin-with-google-btn {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .plan-summary-dropdown {
    top: 64;
  }
}

@media (min-width: 992px) {
  .plan-summary-dropdown {
    top: 100;
  }
}

.pln-segment ion-segment-button::part(indicator-background) {
  background: var(--primary);
}

.pln-segment ion-segment-button.segment-button-checked {
  color: var(--white);
}

.pln-segment.md ion-segment-button.segment-button-checked::part(native) {
  color: var(--primary);
}

.pln-segment ion-segment-button:hover::part(native) {
  color: var(--primary) !important;
  opacity: 1;
}

.pln-segment:not(.md) ion-segment-button.segment-button-checked::part(native),
.pln-segment:not(.md) ion-segment-button.segment-button-checked::part(native) {
  color: var(--white) !important;
}

.pln-segment ion-segment-button::part(native) {
  font-weight: 500;
}

.pln-segment
  ion-segment-button.segment-button-checked:hover::part(native):not(.md) {
  color: var(--primary-superlighter) !important;
}

.pln-segment ion-segment-button:focus::part(native) {
  color: var(--white) !important;
  opacity: 1;
}

.pln-testimonial-slider .swiper-wrapper {
  align-items: center;
}

.pln-testimonial-slider {
  background: var(--white);
  padding-bottom: 20px;
}

.pln-testimonial-slider .swiper-pagination {
  padding-bottom: 8px;
}

.pln-accordion.accordion-expanded ion-item ion-label,
.pln-accordion.accordion-expanding ion-item ion-label,
.pln-accordion.accordion-expanded ion-item ion-icon,
.pln-accordion.accordion-expanding ion-item ion-icon {
  color: var(--white);
}

.pln-accordion.accordion-expanded .item::part(native),
.pln-accordion.accordion-expanding .item::part(native) {
  background: var(--primary) !important;
  --ripple-color: transparent;
}

.pln-accordion ion-item::part(native) ion-ripple-effect {
  display: none !important;
  --ripple-color: transparent !important;
}

.pln-accordion,
.pln-accordion-group {
  --ripple-color: transparent;
}

.no-ripple {
  --ripple-color: transparent;
}

.pln-accordion .ion-padding p {
  margin-top: 0;
}

.pln-accordion .ion-padding {
  border-top: 1px solid var(--lighter);
}

.pln-accordion-group.accordion-group-expand-inset ion-accordion {
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 1px -2px, rgb(0 0 0 / 4%) 0px 2px 2px 0px,
    rgb(0 0 0 / 12%) 0px 1px 5px 0px !important;
}

.pln-plan-option {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 6px;
  border: 1px solid var(--midlighter);
  --border-style: none;
  transition: all 0.1s ease-in-out;
  --background-hover: var(--primary-ultralight);
  --background-hover-opacity: 1;
  --background-activated: var(--primary-superlight);
}
.pln-plan-option.recommended {
  border: 1px solid var(--primary-superlight);
  margin-top: 0px;
  border-top-left-radius: 0;
}

.pln-plan-option.item-active {
  border: 1px solid var(--primary-lighter);
}

.pln-plan-option:hover {
  border-color: var(--primary);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.pln-plan-option::part(native) {
  padding-left: 0px;
  padding-right: 0px;
  --inner-padding-start: 1rem;
  --inner-padding-end: 1rem;
  --inner-padding-top: 0.5rem;
  --inner-padding-bottom: 0.5rem;
  background: var(--white);
}

.pln-plan-option.item-active {
  border: 1px solid var(--primary-lighter);
}

.pln-plan-option.item-active::part(native) {
  background: rgba(255, 247, 245, 1);
}

.pln-plan-option.item-active::part(detail-icon) {
  color: var(--primary);
  opacity: 0.8;
}

.pln-plan-option.recommended::part(native) {
  background: rgba(255, 247, 245, 0.5);
}

.pln-plan-option.recommended::part(detail-icon) {
  color: var(--primary);
  opacity: 0.8;
}

.shadow-sm {
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important;
}
.shadow {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 0 3rem rgba(136, 152, 170, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.cart-action-button::part(native) {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 40px;
  border: 1px solid var(--dark);
}

.cart-action-button.primary::part(native) {
  border-color: var(--primary);
  color: var(--white);
}

.cart-action-row {
  background: white;
  position: sticky;
}

@media (max-width: 767px) {
  .cart-action-row {
    top: 0rem;
    border-bottom: 1px solid var(--midlighter);
    border-radius: 0px !important;
    margin-left: -0.6rem !important;
    margin-right: -0.6rem !important;
    box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important;
  }
}

@media (min-width: 768px) {
  .cart-action-row {
    top: 0.6rem;
    border: 1px solid var(--midlighter);
  }
}

.protein-filter-button {
  transition: all 0.1s ease-in-out;
  background: var(--white);
}

.protein-filter-button:hover {
  color: var(--dark) !important;
  border-color: var(--light) !important;
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important;
}

.protein-filter-button.selected:hover {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important;
}

.protein-filter-button:hover h2 {
  color: var(--dark) !important;
}

.protein-filter-button.selected:hover h2 {
  color: var(--primary) !important;
}

@media (max-width: 767px) {
  .meal-info-image {
    height: 100px;
  }

  .meal-info-title {
    font-size: 1rem;
    line-height: 1.3rem;
    min-height: 2.6rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .meal-info-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0rem;

    font-size: 12px;
    line-height: 1.4;
    text-decoration: none;
    font-style: normal;
    color: var(--dark);
    text-decoration: none;
    font-style: normal;
  }
}

@media (min-width: 768px) {
  .meal-info-image {
    height: 200px;
    overflow: hidden;
  }

  .meal-info-image img {
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
  }

  .meal-info-nutrition {
    font-size: 14px;
  }

  .meal-info-container {
    min-height: 85px;
  }

  .meal-info-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: 14px;
    line-height: 1.4;
    text-decoration: none;
    font-style: normal;
    text-decoration: none;
    font-style: normal;
    color: var(--dark);
    text-decoration: none;
    font-style: normal;
    margin: 0;
  }

  .meal-info-description.compact {
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.2;
  }

  .meal-info-description p {
    font-size: 14px;
    line-height: 1.4;
    text-decoration: none;
    font-style: normal;
    text-decoration: none;
    font-style: normal;
    color: var(--dark);
    text-decoration: none;
    font-style: normal;
    margin: 0;
  }

  .meal-info-description em,
  .meal-info-description span em {
    font-size: 14px;
    line-height: 1.4;
    text-decoration: none;
    font-style: normal;
    text-decoration: none;
    font-style: normal;
    color: var(--dark);
    text-decoration: none;
    font-style: normal;
  }
}

.bg-dark {
  background: var(--dark) !important;
}

.bg-white {
  background: var(--white) !important;
}

.bg-primary {
  background: var(--primary) !important;
}

.border {
  border: 1px solid var(--midlighter);
}

.border-primary {
  border: 1px solid var(--primary) !important;
}

.border-dark {
  border: 1px solid var(--dark) !important;
}

.ion-color-plnlight {
  --ion-color-base: var(--ion-color-plnlight);
  --ion-color-base-rgb: var(--ion-color-plnlight-rgb);
  --ion-color-contrast: var(--ion-color-plnlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-plnlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-plnlight-shade);
  --ion-color-tint: var(--ion-color-plnlight-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.box-modal-toolbar-web {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-bottom: 1px solid var(--midlighter);
  --background: var(--white);
}

.box-modal-toolbar-native {
  border-bottom: 1px solid var(--midlighter);
  --background: var(--white);
}

@media (min-width: 768px) {
  .modal-card.ios {
    --box-shadow: 0px 0px 70px 0px rgba(33, 37, 41, 0.3);
  }
}

/* CSS */
.apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: check-out; /* Use any supported button type. */
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  padding: 0px;
  box-sizing: border-box;
  min-width: 200px;
  min-height: 32px;
  max-height: 64px;
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

.grecaptcha-badge {
  display: none !important;
}

.pln-split-pane-web {
  --side-min-width: 250px;
  --side-max-width: 250px;
}

.pln-toolbar-web {
  --min-height: 60px;
  --color: var(--dark);
  --background: white;
  --border-color: var(--midlighter);
}

.pln-toolbar-web ion-menu-button {
  padding-left: 0.25rem;
}

.nc-footer {
  display: none;
}

.nc-bell-button svg,
.nc-header-title,
.nc-header .mantine-ActionIcon-root svg {
  color: var(--dark);
}

.mantine-Switch-body .mantine-Switch-track {
  background: var(--primary) !important;
}

.pln-activatable {
  transition: transform 400ms cubic-bezier(0.12, 0.72, 0.29, 1) 0s,
    -webkit-transform 400ms cubic-bezier(0.12, 0.72, 0.29, 1) 0s;
}

.pln-activated {
  transform: scale3d(0.97, 0.97, 1);
}

@media (min-width: 768px) {
  .sm-align-items-center {
    align-items: center;
  }
}

textarea:focus {
  outline: auto 1px var(--primary);
}

.mfp-button {
}

.pac-container.pac-logo::after {
  display: none;
}

.pac-container.pac-logo {
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.25) !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid var(--midlighter);
}

.pac-container.pac-logo .pac-item {
  padding: 0.5rem;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
  color: var(--dark);
  font-size: 14px;
}

.pac-container.pac-logo .pac-item .pac-item-query {
  font-size: 14px;
}

.pac-container.pac-logo .pac-item:hover {
  background: var(--primary-ultralight);
}

@media (min-width: 768px) {
  #app-tour-modal {
    --height: 85vh !important;
    --width: 85vw;
    --max-height: 800px;
  }
}

@media (min-width: 768px) {
  .modal-mealdetail,
  .modal-inlinescheduler,
  .new-member-app-onboard-modal,
  .modal-filtermeals {
    --height: 85vh !important;

    --max-height: 800px;
  }
}

.modal-toolbar-native {
  --background: var(--white);
}

.bg-ultralight {
  background: var(--ultralight);
}

.ios .pln-native-tabbar {
  padding-top: 3px;
}

.pln-native-tabbar {
  --background: var(--white);
  --color: #AAB6AA;
  --border: 1px solid var(--midlighter);
}

.pln-native-toolbar {
  --background: var(--white);
  --color: var(--dark);
  --border-color: var(--midlighter);
}

.tracking-tight {
  letter-spacing: -0.02em;
}

body,
html,
p,
h1,
h2,
h3,
h4,
h5,
ion-card-title,
ion-button {
  letter-spacing: -0.02em;
}

img {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: auto;
  font-size: auto;
}

.pln-expresscheckout {
  display: none;
  min-height: 52px;
}

.pln-expresscheckout.ready {
  display: block;
}

.pln-expresscheckout div iframe {
  transition: none !important;
}

.pln-expresscheckout div {
  transition: none !important;
}

.ingredient-list ul {
  list-style:square;
  padding-left: 1.5rem;
  font-size: 17px;
}
