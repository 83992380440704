/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  color-scheme: light;

  --ion-font-family: "Karla", sans-serif;

  --ion-color-primary: #fb6340;
  --ion-color-primary-rgb: 251, 99, 64;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #dd5738;
  --ion-color-primary-tint: #fb7353;

  --ion-color-secondary: #e44925;
  --ion-color-secondary-rgb: 228, 73, 37;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #c94021;
  --ion-color-secondary-tint: #e75b3b;

  --ion-color-tertiary: #e44925;
  --ion-color-tertiary-rgb: 228, 73, 37;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #c94021;
  --ion-color-tertiary-tint: #e75b3b;

  --ion-color-success: #2dce89;
  --ion-color-success-rgb: 45, 206, 137;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28b579;
  --ion-color-success-tint: #42d395;

  --ion-color-warning: #ffd600;
  --ion-color-warning-rgb: 255, 214, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bc00;
  --ion-color-warning-tint: #ffda1a;

  --ion-color-danger: #f5365c;
  --ion-color-danger-rgb: 245, 54, 92;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d83051;
  --ion-color-danger-tint: #f64a6c;

  --ion-color-medium: #8898aa;
  --ion-color-medium-rgb: 136, 152, 170;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #788696;
  --ion-color-medium-tint: #94a2b3;

  --ion-color-pln-medium: #8898aa;
  --ion-color-pln-medium-rgb: 136, 152, 170;
  --ion-color-pln-medium-contrast: #000000;
  --ion-color-pln-medium-contrast-rgb: 0, 0, 0;
  --ion-color-pln-medium-shade: #788696;
  --ion-color-pln-medium-tint: #94a2b3;

  --ion-color-light: #f6f9fc;
  --ion-color-light-rgb: 246, 249, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dbde;
  --ion-color-light-tint: #f7fafc;

  --ion-color-plnlight: #adb5bd;
  --ion-color-plnlight-rgb: 173, 181, 189;
  --ion-color-plnlight-contrast: #000000;
  --ion-color-plnlight-contrast-rgb: 0, 0, 0;
  --ion-color-plnlight-shade: #989fa6;
  --ion-color-plnlight-tint: #b5bcc4;

  --ion-color-white: #adb5bd;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;

  --ultralight: #fcfdfe;
  --superlight: #f6f9fc;
  --midlighter: #e7e9eb;
  --lighter: #d7dbdf;
  --light: #adb5bd;
  --medium: #6c7988;
  --dark: #111411;
  --darker: #000000;
  --white: #ffffff;
  --success: #58de7b;
  --danger: #f5365c;
  --yellow: #fbdb40;

  --primary: #fb6340;
  --primary-light: #fc967e;
  --primary-lighter: #fda692;
  --primary-superlight: #fecbbf;
  --primary-superlighter: #fedfd8;
  --primary-ultralight: #fff7f5;
  --primary-darker: #963b26;

  --ion-background-color: var(--ultralight);
  --ion-text-color: var(--dark);
  --pln-button-box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.25);
}
