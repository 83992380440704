.coupon-banner {
  background: var(--primary) !important;
  padding: 0.5rem;
  color: #fff;
  font-weight: 500;
}

.pln-navbar {
  border-bottom: none;
  background: white;
}

.pln-navbar::after {
  content: none;
}

.pln-navbar-button {
  --padding-start: 0.25rem;
  --padding-end: 0.25rem;
}

@media (min-width: 768px) {
  .pln-checkout-steps {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
