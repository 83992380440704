.mealoption-info-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.upcomingorder-settings-modal {
  --height: auto;
}

.upcomingorder-settings-modal-desktop {
  --max-height: 300px;
}

.referral-modal-desktop {
  --max-height: 370px !important;
}
